
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class business_management extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  tableA_all_data = []

  tableA_total = 0
  tableA_search_data = {
    user_name: '',
    name: '',
    type: 0,
    status: 0
  }

  rule_tableA_search_data = {}

  tableA_detail_dialog = {
    open: false,
    title: '详情'
  }

  tableA_detail_data = {
    evaluate_info: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: []
    },
    base_info: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        is_evaluate: 0,
        evaluator_type: 0,
        purpose: 0,
        finish_time: '',
        fee: 0,
        entrust_contact: '',
        entrust_phone: '',
        wechat: '',
        email: '',
        remark: '',
        name: '',
        code: '',
        modality: [],
        res_source: [],
        res_source_unit: '',
        source_unit_nature: [],
        project_name: '',
        type: 0,
        industry_category_id: [],
        related_industry_ids: [],
        custome_related_industries: '',
        contact: '',
        phone: '',
        finish_units: [] as any[]
      }
    },
    profile_info: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        summary: [] as any[]
      }
    },
    wbs_deconstruct: {
      menu_type: 0,
      menu: '',
      json_data: {
        wbs: [] as any[]
      }
    },
    techinque_level: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        core_technologies: [] as any[],
        techinque_level: {
          maturity: 0,
          maturity_reason: '',
          innovation: 0,
          innovation_reason: '',
          advanced: 0,
          advanced_reason: ''
        },
        description: '',
        question_suggest: ''
      }
    },
    bmk_analysis: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        obj1_info: '',
        obj2_info: '',
        bmk: [] as any[]
      }
    },
    promotion: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        extend: [] as any[]
      }
    },
    economic_benefit: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        economic_benefit: [] as any[]
      }
    },
    social_benefit: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        social_benefit: [] as any[]
      }
    },
    intellectual_property: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        intellectual_property: [] as any[]
      }
    },
    team: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        team_table: [] as any[],
        team_txt: [] as any[]
      }
    },
    risk: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        risk: [] as any[]
      }
    },
    input_and_output: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        end_time: '',
        facility_charge: 0,
        materials_expenses: 0,
        processing_costs: 0,
        laboratory_fee: 0,
        power_cost: 0,
        travel_expense: 0,
        conference_fee: 0,
        communication_fee: 0,
        transaction_fee: 0,
        service_fee: 0,
        consulting_fee: 0,
        administrative_fee: 0,
        incentive_fee: 0,
        rest_fee: 0,
        sales_revenue_fee: 0,
        append_fee: 0,
        total_fee: 0,
        profit_fee: 0,
        income_tax_rate_fee: 0,
        income_tax_fee: 0,
        profit_afte_tax_fee: 0,
        output_description: ''
      }
    },
    technology_novelty: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        check_organization: '',
        report: '',
        report_date: '',
        novelty_point: '',
        search_results: '',
        other_notes: ''
      }
    },
    detect_test: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        check_organization: '',
        testing_institutions: '',
        detected_name: '',
        report: '',
        report_date: '',
        search_results: '',
        other_notes: ''
      }
    },
    technical_valuation: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        valuation: [] as any[]
      }
    },
    financing_needs: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        valuation: [] as any[]
      }
    },
    files: {
      id: 0,
      menu_type: 0,
      menu: '',
      json_data: {
        files: []
      }
    },
    res_menus: [] as any[]
  }

  tableA_edit_dialog = {
    open: false,
    title: '审核'
  }

  tableA_edit_data = {
    id: 0,
    status_: 0
  }

  rule_tableA_edit_data = {}

  tableA_edit1_dialog = {
    open: false,
    title: '指派组长'
  }

  tableA_edit1_data = {
    id: 0,
    group_leader_id: []
  }

  rule_tableA_edit1_data = {}

  tableA_edit2_dialog = {
    open: false,
    title: '指派审查员'
  }

  tableA_edit2_data = {
    id: 0,
    texamine_id: 0
  }

  rule_tableA_edit2_data = {}

  tableA_edit3_dialog = {
    open: false,
    title: '盖章确认'
  }

  tableA_edit3_data = {
    id: 0
  }

  rule_tableA_edit3_data = {}

  leaders: any[] = []
  texamines: any[] = []
  group_leader_id_215 = new Map<any, string>([])
  texamine_id_216 = new Map<any, string>([])
  res_source_219 = new Map<any, string>([
    [1, '横向课题'],
    [2, '横向课题'],
    [3, '其他']
  ])
  type_221 = new Map<any, string>([
    [1, '应用技术'],
    [2, '基础理论'],
    [3, '软课题研究'],
    [4, '教育教学'],
    [5, '创新能力与条件建设'],
    [6, '技术转移与推广'],
    [7, '文学艺术实践'],
    [8, '其它']
  ])
  status_223 = new Map<any, string>([
    [0, '草稿'],
    [1, '待指派专家'],
    [2, '待专家评'],
    [3, '已完成'],
    [4, '待申请'],
    [5, '待审核'],
    [6, '审核不通过'],
    [7, '待指派组长'],
    [8, '待指派报告审查员'],
    [9, '待指派项目评估员'],
    [10, '待评估员评估'],
    [11, '待审核评估'],
    [12, '评估退回'],
    [13, '待指定报告撰写人'],
    [14, '待撰写报告'],
    [15, '待组长审核报告'],
    [16, '报告退回撰写'],
    [17, '待审查报告'],
    [18, '报告退回组长'],
    [19, '待盖章确认']
  ])
  status__224 = new Map<any, string>([
    [6, '不通过'],
    [7, '通过']
  ])
  modality_225 = new Map<any, string>([
    [1, '新方法'],
    [2, '新产品'],
    [3, '新工艺'],
    [4, '新材料/配方'],
    [5, '新设备/工具'],
    [6, '软件系统'],
    [7, '新品种'],
    [8, '其他']
  ])
  evaluator_type_240 = new Map<any, string>([
    [1, '第三方评'],
    [2, '公开邀请评估'],
    [3, '自邀专家评']
  ])
  purpose_241 = new Map<any, string>([
    [1, '技术交易(包含转让、许可、作价投资、质押融资等)'],
    [2, '应用推广(包含市场推广、项目引进等)'],
    [3, '水平鉴定(包含报奖、人才评价、成果管理、创新规划等)'],
    [4, '项目管理(包含项目立项、项目监督、项目结题等)']
  ])
  modality_251 = new Map<any, string>([
    [1, '新方法'],
    [2, '新产品'],
    [3, '新工艺'],
    [4, '新材料/配方'],
    [5, '新设备/工具'],
    [6, '软件系统'],
    [7, '新品种'],
    [8, '其他']
  ])
  res_source_252 = new Map<any, string>([
    [1, '纵向课题'],
    [2, '横向课题'],
    [3, '其他']
  ])
  source_unit_nature_254 = new Map<any, string>([
    [1, '高校'],
    [2, '科研机构'],
    [3, '其他事业单位'],
    [4, '社会组织'],
    [5, '国企'],
    [6, '名企'],
    [7, '外企'],
    [8, '混合所有制企业'],
    [9, '其他']
  ])
  type_256 = new Map<any, string>([
    [1, '应用技术'],
    [2, '基础理论'],
    [3, '软课题研究'],
    [4, '教育教学'],
    [5, '创新能力与条件建设'],
    [6, '技术转移与推广'],
    [7, '文学艺术实践'],
    [8, '其它']
  ])
  finsh_way_288 = new Map<any, string>([
    [1, '独立开发'],
    [2, '委外开发'],
    [3, '外部购买']
  ])
  view_sort_290 = new Map<any, string>([
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5']
  ])
  result_form_291 = new Map<any, string>([[1, '服务业']])
  before_maturity_292 = new Map<any, string>([])
  after_maturity_293 = new Map<any, string>([])
  maturity_308 = new Map<any, string>([
    [0, '0'],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6'],
    [7, '7'],
    [8, '8'],
    [9, '9'],
    [10, '10']
  ])
  innovation_310 = new Map<any, string>([
    [0, '0'],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6'],
    [7, '7'],
    [8, '8'],
    [9, '9'],
    [10, '10']
  ])
  advanced_312 = new Map<any, string>([
    [0, '0'],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6'],
    [7, '7'],
    [8, '8'],
    [9, '9'],
    [10, '10']
  ])

  tableA_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.tableA_search_data.user_name,
      name: this.tableA_search_data.name,
      type: this.tableA_search_data.type,
      status: this.tableA_search_data.status
    }
    Api.http_business_managementtableA0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_all_data = res.data.data
        this.tableA_total = res.data.total
      }
    })
  }
  tableA_detail_init(row) {
    Api.http_business_managementtableAget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_detail_data.evaluate_info = res.data.evaluate_info
        this.tableA_detail_data.base_info = res.data.base_info
        this.tableA_detail_data.profile_info = res.data.profile_info
        this.tableA_detail_data.wbs_deconstruct = res.data.wbs_deconstruct
        this.tableA_detail_data.techinque_level = res.data.techinque_level
        this.tableA_detail_data.bmk_analysis = res.data.bmk_analysis
        this.tableA_detail_data.promotion = res.data.promotion
        this.tableA_detail_data.economic_benefit = res.data.economic_benefit
        this.tableA_detail_data.social_benefit = res.data.social_benefit
        this.tableA_detail_data.intellectual_property
          = res.data.intellectual_property
        this.tableA_detail_data.team = res.data.team
        this.tableA_detail_data.risk = res.data.risk
        this.tableA_detail_data.input_and_output = res.data.input_and_output
        this.tableA_detail_data.technology_novelty = res.data.technology_novelty
        this.tableA_detail_data.detect_test = res.data.detect_test
        this.tableA_detail_data.technical_valuation
          = res.data.technical_valuation
        this.tableA_detail_data.financing_needs = res.data.financing_needs
        this.tableA_detail_data.files = res.data.files
        this.tableA_detail_data.res_menus = res.data.res_menus
        this.tableA_detail_dialog.open = true
      }
    })
  }
  tableA_detail_cancle() {
    this.tableA_detail_dialog.open = false
  }
  tableA_detail_ok() {
    this.tableA_detail_dialog.open = false
  }
  tableA_edit_init(row) {
    Api.http_business_managementtableAget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_edit_data.id = res.data.id
        this.tableA_edit_data.status_ = res.data.status_
        this.tableA_edit_dialog.open = true
      }
    })
  }
  tableA_edit_cancle() {
    this.tableA_edit_dialog.open = false
  }
  tableA_edit_ok() {
    this.$refs.ref_tableA_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableA_edit_data.id,
          status_: this.tableA_edit_data.status_
        }
        Api.http_business_managementtableA5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableA_edit_dialog.open = false
            this.tableA_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableA_edit1_init(row) {
    this.get_leaders(row.id)

    Api.http_business_managementtableAget6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_edit1_data.id = res.data.id
        this.tableA_edit1_data.group_leader_id = res.data.group_leader_id
        this.tableA_edit1_dialog.open = true
      }
    })
  }
  tableA_edit1_cancle() {
    this.tableA_edit1_dialog.open = false
  }
  tableA_edit1_ok() {
    this.$refs.ref_tableA_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableA_edit1_data.id,
          group_leader_id: this.tableA_edit1_data.group_leader_id
        }
        Api.http_business_managementtableA6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableA_edit1_dialog.open = false
            this.tableA_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableA_edit2_init(row) {
    this.get_texamines(row.id)

    Api.http_business_managementtableAget7({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_edit2_data.id = res.data.id
        this.tableA_edit2_data.texamine_id = res.data.texamine_id
        this.tableA_edit2_dialog.open = true
      }
    })
  }
  tableA_edit2_cancle() {
    this.tableA_edit2_dialog.open = false
  }
  tableA_edit2_ok() {
    this.$refs.ref_tableA_edit2_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableA_edit2_data.id,
          texamine_id: this.tableA_edit2_data.texamine_id
        }
        Api.http_business_managementtableA7(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableA_edit2_dialog.open = false
            this.tableA_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableA_edit3_init(row) {
    Api.http_business_managementtableAget8({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_edit3_data.id = res.data.id
        this.tableA_edit3_dialog.open = true
      }
    })
  }
  tableA_edit3_cancle() {
    this.tableA_edit3_dialog.open = false
  }
  tableA_edit3_ok() {
    this.$refs.ref_tableA_edit3_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableA_edit3_data.id
        }
        Api.http_business_managementtableA8(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableA_edit3_dialog.open = false
            this.tableA_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableA_init() {
    this.tableA_get_all_data(0, 10)
  }
  get_leaders(id = -1) {
    Api.http_business_managementtableAleaders0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.leaders = res.data.data
      }
    })
  }
  get_texamines(id = -1) {
    Api.http_business_managementtableAtexamines0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.texamines = res.data.data
      }
    })
  }
  created() {
    this.tableA_init()
  }
}
